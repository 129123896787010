/**
 * Addition to library styles, fixes class names conflict. See:
 *   https://redmine.ack.ee/issues/70501
 *   https://redmine.ack.ee/issues/72586
 *   https://github.com/bl00mber/react-phone-input-2/issues/544
 */
.react-tel-input .flag {
    padding: 0;
    margin: 0;
    min-width: 0;
    min-height: 0;
    border: none;
}
