@import '~react-toastify/dist/ReactToastify.css';

#layout-toast {
    position: relative;

    .Toastify__toast-container {
        position: absolute;
        top: 0;
        width: 400px;
        max-width: 90%;
    }
}
