/* 
    https://create-react-app.dev/docs/adding-css-reset#indexcss 
*/
@import-normalize;

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

* {
    margin: 0;
    padding: 0;
}
